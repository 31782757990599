import React from "react"
import tw from 'twin.macro'

const styles = {
  element: [
    tw`stroke[#840000] fill[#FFFFC2] dark:(stroke[#FF6060] fill[#27271D])`
  ],
  redFill: [
    tw`fill[#840000] dark:(fill[#FF6060])`
  ],
  redStroke: [
    tw`stroke[#840000] dark:(stroke[#FF6060])`
  ],
  greenFill: [
    tw`fill[#008400] dark:(fill[#68B185])`
  ],
  greenStroke: [
    tw`stroke[#008400] dark:(stroke[#68B185])`
  ],
  labelFill: [
    tw`fill[#008484] dark:(fill[#84DBDB])`
  ]
}

function AdaptorDiagram() {
  return (
    <div tw='my-8 px-4 lg:(my-24 px-6)'>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="530"
        height="179"
        viewBox="0 0 530 179"
        tw='w-full'
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <circle cx="132" cy="118" r="4.5" css={styles.redStroke}></circle>
          <g transform="translate(0 75)">
            <path
              css={styles.element}
              strokeLinejoin="round"
              strokeWidth="4"
              d="M16 0H102V101H16z"
            ></path>
            <path
              css={styles.redFill}
              d="M1 71h16v32H1a1 1 0 01-1-1V72a1 1 0 011-1z"
            ></path>
          </g>
          <path
            css={styles.element}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M292 75H378V176H292z"
          ></path>
          <path
            css={styles.redFill}
            d="M277 146h16v32h-16a1 1 0 01-1-1v-30a1 1 0 011-1z"
          ></path>
          <path css={styles.greenStroke} strokeWidth="4" d="M524 118L380 118"></path>
          <path
            css={styles.redStroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M30 147L37 154.5 44.5 147 44.5 89 133 89"
          ></path>
          <path
            css={styles.redStroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M51.5 147L59 154.5 66 147 66 118 133 118"
          ></path>
          <path
            css={styles.redStroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M73 147L80.5 154.5 88 147 133 147"
          ></path>
          <path
            css={styles.greenStroke}
            strokeWidth="4"
            d="M132 89L190 89 190 2 524 2 524 147 380 147"
          ></path>
          <path
            css={styles.greenStroke}
            strokeWidth="4"
            d="M132 147L248 147 248 30 451 30 451 89 380 89"
          ></path>
          <circle cx="524" cy="118" r="6" css={styles.greenFill}></circle>
          <g
            css={styles.redStroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            transform="translate(306 89)"
          >
            <path d="M0 58L7 65.5 14.5 58 14.5 0 103 0"></path>
            <path d="M21.5 58L29 65.5 36 58 36 29 103 29"></path>
            <path d="M43 58L50.5 65.5 58 58 103 58"></path>
          </g>
          <text
            css={styles.labelFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="21" y="58">
              Canon In
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="111" y="85">
              S
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="387" y="85">
              S
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="111" y="115">
              R
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="387" y="115">
              R
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="111" y="143">
              T
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="387" y="143">
              T
            </tspan>
          </text>
          <text
            css={styles.labelFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="295" y="58">
              Sony Out
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}

function TimelapseDiagram() {
  return (
    <div tw='my-8 px-4 lg:(my-24 px-6)'>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="630"
        height="173"
        viewBox="0 0 630 173"
        tw='w-full'
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            css={styles.element}
            strokeWidth="4"
            d="M2 98.5L30.5 70 73 84 87 70 244 70 244 56 288 56 288 171 244 171 244 157 87 157 73 141 30.5 155.5 2 127z"
          ></path>
          <path css={styles.redStroke} strokeWidth="4" d="M102 72L102 155"></path>
          <path css={styles.redStroke} strokeWidth="4" d="M130 72L130 155"></path>
          <path
            css={styles.greenStroke}
            strokeWidth="4"
            d="M290 84L415 84 415 70 572 70 572 84"
          ></path>
          <path
            css={styles.greenStroke}
            strokeWidth="4"
            d="M572 144L572 156 415 156 415 141 290 141"
          ></path>
          <path css={styles.redStroke} strokeWidth="4" d="M572 68L572 84"></path>
          <path css={styles.redStroke} strokeWidth="4" d="M572 142L572 158"></path>
          <path css={styles.redStroke} strokeWidth="4" d="M560 84H584V142H560z"></path>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="37" y="120">
              T
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="180" y="120">
              S
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="294" y="81">
              S
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="294" y="110">
              R
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="294" y="138">
              T
            </tspan>
          </text>
          <text
            css={styles.redFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
            transform="matrix(0 -1 1 0 1 228)"
          >
            <tspan x="108" y="121">
              R
            </tspan>
          </text>
          <path css={styles.redStroke} strokeWidth="4" d="M526 110L554.5 138.5"></path>
          <path
            css={styles.redStroke}
            strokeWidth="4"
            d="M555 130L555 139 546 139"
          ></path>
          <path css={styles.redStroke} strokeWidth="4" d="M526 93L554.5 121.5"></path>
          <path
            css={styles.redStroke}
            strokeWidth="4"
            d="M555 113L555 122 546 122"
          ></path>
          <text
            css={styles.labelFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="128.546" y="16" fontVariant="small-caps">
              2.5mm
            </tspan>
            <tspan x="122.056" y="41">
              Stereo
            </tspan>
          </text>
          <text
            css={styles.labelFill}
            fontFamily="ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace"
            fontSize="21"
            fontWeight="normal"
          >
            <tspan x="592.528" y="121">
              LDR
            </tspan>
          </text>
          <path css={styles.redStroke} strokeWidth="4" d="M288 84L315 84"></path>
          <path css={styles.redStroke} strokeWidth="4" d="M288 141L315 141"></path>
          <path
            css={styles.redStroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M288 113L313 113"
          ></path>
          <circle cx="315" cy="113" r="4.5" css={styles.redStroke}></circle>
        </g>
      </svg>
    </div>
  );
}

export { AdaptorDiagram, TimelapseDiagram }
